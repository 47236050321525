exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-210524-js": () => import("./../../../src/pages/marketing-210524.js" /* webpackChunkName: "component---src-pages-marketing-210524-js" */),
  "component---src-pages-marketing-210630-js": () => import("./../../../src/pages/marketing-210630.js" /* webpackChunkName: "component---src-pages-marketing-210630-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-usecase-assets-js": () => import("./../../../src/pages/usecase-assets.js" /* webpackChunkName: "component---src-pages-usecase-assets-js" */),
  "component---src-pages-usecase-material-js": () => import("./../../../src/pages/usecase-material.js" /* webpackChunkName: "component---src-pages-usecase-material-js" */),
  "component---src-pages-usecase-sales-js": () => import("./../../../src/pages/usecase-sales.js" /* webpackChunkName: "component---src-pages-usecase-sales-js" */)
}

