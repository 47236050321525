module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BoxHero","short_name":"BoxHero","start_url":"/","background_color":"#ffffff","theme_color":"#4f67ff","display":"minimal-ui","icon":"src/images/favicon_512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a44f0b73f765bee03411285b6026e8e6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ko"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://www.boxhero-app.com","i18nextOptions":{"ns":["translation","index","footer","header","url","about","features","pricing","layout","language-selector","blog"],"fallbackLng":false,"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/(privacy|tos)","languages":[]},{"matchPath":"/marketing-210524","languages":["ko"]},{"matchPath":"/marketing-210630","languages":["ko"]},{"matchPath":"/marketing","languages":["en","ko"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
